/* Typography */
.h1 {
  @apply text-4xl font-extrabold tracking-tighter;
}

.h2 {
  @apply text-3xl font-extrabold tracking-tighter;
}

.h3 {
  @apply text-3xl font-extrabold;
}

.h4 {
  @apply text-2xl font-extrabold tracking-tight;
}

@screen md {
  .h1 {
    @apply text-5xl;
  }

  .h2 {
    @apply text-4xl;
  }
}

/* Buttons */
.btn,
.btn-lg,
.btn-sm,
.btn-xs {
  @apply font-medium text-sm inline-flex items-center justify-center border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out;
}

.btn {
  @apply px-3 py-2;
}

.btn-lg {
  @apply px-4 py-3;
}

.btn-sm {
  @apply px-2 py-1;
}

.btn-xs {
  @apply px-2 py-0.5;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
